.tabs {
  margin-bottom: 1rem;
}

.tabs > li {
  cursor: pointer;
}

.eliminar {
  color: #dc3545;
}

.eliminar:hover {
  color: #c82333;
}

.config-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}

.config-list > li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.5rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  margin-bottom: 4px;
}

.config-list > li > img {
  max-height: 2.5rem;
}

.delete-config {
  float: right;
  padding-right: 0;
}

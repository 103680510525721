.navbar {
  height: 50px;
  -webkit-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  z-index: 10;
  background-color: #fff !important;
}

.toggle {
  margin-right: 1rem;
  color: #2D2926;
  font-size: 1.3em;
}

.logo {
  height: 100%;
}

.dropdown-menu {
  border-radius: .15rem;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.15);
  top: 105%;
}
.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.modal-mobile {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
}

.card {
  z-index: 1;
  width: 45%;
  border: none;
  border-radius: .7rem;
  overflow: hidden;
  max-height: 90vh;
}

.card-mobile {
  z-index: 1;
  width: 100%;
  height: 35vh;
  border: none;
  overflow: hidden;
  border-radius: .7rem;
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.card-mobile-max {
  height: 90vh;
}

.header {
  background-color: #0057B8;
  border-color: #0057B8;
  color: #fff;
  padding: 0 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.expand-button {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: .75rem .75rem;
  text-align: center;
}

.expand-button-line {
  width: 35%;
  height: .25rem;
  background-color: #fff;
  display: block;
  margin: auto;
  border-radius: .5rem;
}

.card-body {
  overflow-y: auto;
}

.card-body-mobile {
  overflow-y: auto;
  padding-bottom: 5rem;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
}
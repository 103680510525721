.header {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.header > svg {
  margin-right: 0.5rem;
  color: #17a2b8;
  font-size: 1.2em;
}

.image-wrapper {
  background-color: #f5f5f5;
  padding: 0.25rem;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-wrapper > img {
  max-width: 100%;
  max-height: 8rem;
}

.alert {
  width: 30vw;
  border: none;
  -webkit-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.25);
}

.alert>h5 {
  font-weight: bold;
  margin-bottom: 0;
}

.mobile {
  width: 75vw;
}
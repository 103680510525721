.buttons-wrapper {
  position: absolute;
  bottom: 0;
  z-index: 999;
  padding: 1rem 0.7rem;
  width: auto;
}

.buttons-wrapper-mobile {
  position: absolute;
  bottom: 0;
  z-index: 999;
  padding: 1rem 0.7rem 1.5rem;
  width: 100vw;
}

.button {
  margin-top: .75rem;
  margin-right: .5rem;
}

.full-width {
  width: 100%;
}
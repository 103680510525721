.desc {
  font-size: 0.85em;
  color: #777;
  margin-bottom: 1.5rem;
}

.separador {
  padding: 0.5rem;
}

.sub-separador {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 0.25rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.sub-separador:hover :global h5,
.sub-separador:hover :global svg {
  color: #0056b3;
}

.buttons {
  margin-bottom: 1rem;
  padding-top: 1.3rem;
}

.buttons > button {
  padding: 0.375rem 2.5rem;
}

.vehicle-card > :global.card > div > div > span {
  font-size: 0.85em;
}

.toggled {
  height: 0;
  overflow: hidden;
}

.line-card {
  margin-bottom: 0.5rem;
}

.line-card :global .card-body {
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
}

.line {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.icono-wrapper {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icono {
  max-height: 2rem;
}

.iconos-row {
  display: flex;
}

.iconos-row > .icono-wrapper {
  margin-right: 0.5rem;
}

.header {}

.body {
  display: flex;
  height: calc(100vh - 50px);
  overflow: hidden;
  position: relative;
  background-color: #f2f2f2;
}

.left {
  z-index: 10;
}

.right {
  overflow-y: auto;
  flex-basis: 100%;
  padding: 50px 0;
}
.logo {
  width: 150px;
  display: block;
  margin: auto;
  margin-bottom: .5rem;
}

.title {
  font-weight: bold;
  text-align: center;
  color: #2D2926;
}

.link {
  display: block;
  margin-bottom: 1.5rem;
}

.button {
  display: block;
  margin: auto;
  font-weight: bold;
}
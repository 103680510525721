.infocol {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.infocol>span:first-child {
  color: #2D2926;
  font-weight: bold;
  font-size: 0.85em;
}
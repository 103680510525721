.statuses {
  display: flex;
  align-items: center;
}

.statuses > * {
  margin-right: 0.25rem;
}

.statuses > *:last-child {
  margin-right: 0;
}

.no-credential {
  background-color: #f5f5f5;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #777;
}

.header-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar {
  background-color: #f2f2f2;
  height: 100%;
  max-height: 100%;
  width: 300px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.show {
  width: 0px;
  /* max-width: 0px; */
  overflow: hidden;
  box-shadow: none;
}

.safari.show .header,
.safari.show .body {
  width: 0;
}

.safari.show .body {
  padding: 0;
}

.header {
  flex: 0;
  width: 300px;
  max-width: 80vw;
  padding: .5rem 0;
}

.header-logo {
  max-width: 60%;
  display: block;
  margin: auto;
  margin-top: 1rem;
}

.body {
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
  width: 300px;
  max-width: 80vw;
}

.body-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.clear-button {
  border-radius: .25rem;
  /* border-bottom: 1px solid #ccc; */
  padding: .5rem;
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #333;
}

.clear-button:hover {
  text-decoration: none;
  background-color: #ccc;
  color: #333;
}

.body-list>li {
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  border-bottom: 1px solid #ccc;
}

.body-list>li:last-child {
  /* border-bottom: 0; */
}

.body-list>li:hover {
  background-color: #ccc;
}

.body-list>li>button {
  padding: .5rem;
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #333;
}

.body-list>li>button:hover {
  color: #333;
  text-decoration: none;
}

.active {
  background-color: #ccc;
}
.notification-card {
  min-height: 80vh;
  height: 80vh;
  flex-direction: row;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.card-body {
  padding-left: 2.5rem;
  overflow-y: auto;
}

.iframe {
  height: 100vh !important;
}

.notification-mobile-card {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  height: -webkit-fill-available;
}

.left {
  z-index: 999;
  background-color: #333;
  flex-basis: 0%;
}

.card-mobile-body {
  flex: 1;
  flex-basis: 100%;
  position: relative;
  overflow-y: auto;
  min-width: 100%;
}

.header-mobile {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: flex-end
}

.title-mobile {
  margin-bottom: 0;
  margin-left: .5rem;
  flex: 1;
}
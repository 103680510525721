.card {
  padding: .5rem;
  display: block;
  border-radius: .5rem;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}

.card:hover {
  text-decoration: none;
  color: #fff;
}

.icon {
  max-width: 40%;
  display: block;
  margin: auto;
  margin-bottom: .5rem;
}

.name {
  font-size: 1.2em;
}
.wrapper {
  position: relative;
}

.input {
  cursor: pointer;
}

.color-wrapper {
  display: flex;
  align-items: flex-end;
}

.color {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  height: 2.4rem;
  width: 100%;
  cursor: pointer;
}

.picker {
  position: absolute;
  z-index: 100;
  top: 4.5rem;
}

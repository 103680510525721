.wrapper {
  border-radius: .25rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.map-wrapper {
  height: 15rem;
  width: 100%;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}
.browser-card {
  height: 80vh;
  overflow: hidden;
}

.browser-card-header {
  flex: 0;
  z-index: 1000;
  min-height: auto;
}

.card-map {
  flex: 1;
  display: flex;
  overflow: hidden;
  position: relative;
}

.card-map-mobile {
  display: flex;
  overflow: hidden;
  position: relative;
  /* height: -webkit-fill-available; Este se rompe con el teclado. */
  position: fixed;
  /* To fix the navbar problem. */
  height: 100%;
  width: 100vw;
}

.left {
  z-index: 999;
  background-color: #333;
  flex-basis: 0%;
}

.right {
  flex: 1;
  flex-basis: 100%;
  position: relative;
}

.map-buttons {
  position: absolute;
  z-index: 999;
  bottom: 0;
  padding: 1rem 0.7rem;
}

.map-buttons>button {
  margin-right: .5rem;
}

.btn-selectroute {
  padding: .375rem 3rem;
  font-weight: bold;
}

.route-selected {
  background-color: #0057B8;
  border-color: #0057B8;
}

.route-selected:active,
.route-selected:focus,
.route-selected:hover {
  background-color: #FF3F29 !important;
  border-color: #FF3F29 !important;
}

.floating-button {
  position: absolute;
  z-index: 999;
  left: 0.7rem;
  top: 1rem;
}

.iframe {
  height: 100vh !important;
}
.wrapper {
  border-radius: .5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.map-wrapper {
  width: 100%;
  position: relative;
}

.leaflet-container {
  width: 100%;
  height: 100%;
}

.buttons {
  background-color: #fff;
}

.buttons>button {
  width: 50%;
  border-radius: 0;
  font-weight: bold;
}
.back {
  padding-left: 0;
}

.tabs {
  margin-bottom: 1rem;
  background-color: #f5f5f5;
  border-radius: 0.25rem;
  justify-content: space-between;
}

.tabs>li {
  flex: 1;
}

.tabs>li>button {
  width: 100%;
  position: relative;
}

.tabs>li>a:hover {
  cursor: pointer;
}

.tabs>li> :global.active {
  background-color: #6c757d !important;
}

.tabs>li> :global.active:hover {
  background-color: #545b62;
  color: #fff;
}

.map {
  height: 70vh;
}

.buttons {
  width: 100%;
  margin-bottom: 1rem;
}

.dl>dt {
  color: #2D2926;
}

.dl>dd {
  margin-bottom: 0;
}

.poi-list {
  padding: 0;
  list-style: none;
  max-height: 35vh;
  overflow-y: auto;
}

.poi-list>li {
  padding: 0.25rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: #2D2926;
}

.poi-list>li:nth-child(odd) {
  background-color: #f5f5f5;
}

.poi-list-desc {
  color: #777;
  font-size: 0.85em;
  margin-bottom: 0.5rem;
}

.poi-list-btn-group {
  margin-bottom: 0.25rem !important;
}

.error-badge {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #dc3545;
  opacity: 0.5;
  border-radius: 0.25rem;
}

.disabled {
  position: absolute;
  width: calc(100% - 30px);
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled>p {
  margin-bottom: 0;
  background-color: #fff;
  width: 100%;
  text-align: center;
  color: #2D2926;
  padding: 1rem;
}

.message {
  color: #777;
}
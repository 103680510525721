.header {
  display: flex;
  align-items: center;
}

.header-btn {
  margin-right: 1rem;
  color: #2D2926;
}

.header-logo {
  max-width: 5%;
  margin-right: 1rem;
}

.header-text {
  display: flex;
  flex-direction: column;
  align-self: flex-end
}
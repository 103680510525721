.header {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.header > svg {
  margin-right: 0.5rem;
  color: #17a2b8;
  font-size: 1.2em;
}

.chofer {
  text-align: center;
}

.chofer > img {
  height: 6rem;
  width: 6rem;
  border-radius: 5rem;
  margin: auto;
}

.configuration {
  display: inline-block;
  max-width: 2rem;
}

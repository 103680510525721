.icono-wrapper {
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icono {
  max-height: 2rem;
}
.sidebar {
  background-color: #f2f2f2;
  height: 100%;
  max-height: 100%;
  width: 250px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 3px 6px 4px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  /* Transitions */
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.minwidth {
  min-width: 250px;
}

.show {
  width: 0px;
  overflow: hidden;
  box-shadow: none;
}

.safari.show .header,
.safari.show .body {
  width: 0;
}

.safari.show .body {
  padding: 0;
}

.header {
  flex: 0;
  width: 250px;
  max-width: 80vw;
  padding: .5rem 0;
}

.header-logo {
  max-width: 60%;
  display: block;
  margin: auto;
  margin-top: 1rem;
}

.header-buttons {
  display: flex;
  justify-content: space-around;
}

.header-btn {
  font-size: 1.5em;
  color: #2D2926
}

.body {
  padding: 1rem;
  width: 250px;
  max-width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
}

.body-list {
  padding: 0;
  margin: 0;
  list-style: none;
  flex: 1;
  margin-bottom: 2.5rem;
  width: 100%;
}

.body-list>li,
.body-list>li>a {
  font-weight: bold;
  padding: .5rem;
  display: flex;
  align-items: center;
  border-radius: .25rem;
  color: #2D2926;
}

.body-list>li>svg,
.body-list>li>a>svg {
  font-size: 1.5em;
  margin-right: 1rem;
  width: 25px !important;
}

.body-list>li {
  padding: 0;
  margin-bottom: .5rem;
}

.body-list>li>a {
  width: 100%;
}

.login {
  width: 100%;
}

.logout,
.logout:hover {
  color: #2D2926;
}

.active {
  background-color: #ccc;
}
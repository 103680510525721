.header {
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.header > svg {
  margin-right: 0.5rem;
  color: #17a2b8;
  font-size: 1.2em;
}

.logo {
  max-width: 35%;
  margin: auto;
  display: block;
}

.description {
  color: #777;
  font-size: 0.9em;
}

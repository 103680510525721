.wrapper {
  display: flex;
}

.input-date {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.input-hours {
  border-radius: 0;
  border-left: none;
  border-right: none;
  padding: 0.375rem 0.5rem;
  text-align: center;
  width: 49%;
}

.input-minutes {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  padding: 0.375rem 0.5rem;
  text-align: center;
  width: 49%;
}

.input-colons {
  padding: 0.375rem 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  background-color: #fff;
}

.disabled {
  background-color: #e9ecef;
  opacity: 1;
}
